import { interactionsApi } from '~/api'
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useMainStore } from '~/store/main/main.store'
import { setCompare } from '~/composible/setFavourites'

export function createInteraction() {
  const $route = useRoute()
  const cityStore = useCities()
  const tariffsStore = useTariffs()
  const mainStore = useMainStore()
  const { compareListTariffs } = setCompare()
  const currentCity = computed(() => cityStore.getCity)
  const houseUrl = computed(() => tariffsStore.getHouseUrl)
  const getAddressText = computed(() => tariffsStore.getAddressText)
  const street = computed(() => cityStore.getDataStreet)
  const houseId = computed(() => tariffsStore.getHouseId)
  const house = computed(() => tariffsStore.getHouse)
  const passThroughProviderId = computed(() => tariffsStore.getPassThroughProviderId)
  const utmArr = computed(() => mainStore.getUtmArr)
  const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
  const provider = computed(() => mainStore.getDomainConfig)
  const diProvider = computed(() => mainStore.getDiProvider)
  const tariffsViewed = useCookie('tariffsViewed')

  const ctx = useNuxtApp()

  const gacid = () => {
    let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)')
    const raw = match ? decodeURIComponent(match[1]) : null
    if (raw) {
      match = raw.match(/(\d+\.\d+)$/)
    }
    const gacid = match ? match[1] : null
    if (gacid) {
      return gacid
    }
  }

  const yaid = () => {
    const match = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)')
    return match ? decodeURIComponent(match[1]) : ''
  }

  const partnerId = useCookie('partner_id')

  const cI = async (
    complete: boolean,
    lead?: any,
    tariff?: any,
    meta?: any,
    extra?: any,
    client?: any,
    address?: any,
  ) => {
    let res
    try {
      res = (
        await interactionsApi.createInteraction(
          {
            interactionCreate: {
              lead,
              complete,
              fake_tariffs: tariff?.markers?.fake,
              address: {
                auto_url: houseUrl.value,
                full_address: getAddressText.value?.fullAddress,
                city_fias_id: currentCity.value.fias_id,
                street_fias_id: street.value?.fias_id,
                house_id: houseId.value || undefined,
                house_dadata: house.value?.dadata || {},
                ...address,
              },
              client,
              tariff: {
                comparison_tariff_ids: compareListTariffs.value?.map(el => el.replace(/\D/g, '')),
                opened_tariff_ids: tariffsViewed.value?.map(el => el.replace(/\D/g, '')),
                ...tariff,
                provider_id: tariff?.provider_id || (provider.value ? null : passThroughProviderId.value) || undefined,
                fake: undefined,
              },
              meta: {
                utm: utmArr.value?.length
                  ? Object.fromEntries(utmArr.value)
                  : undefined,
                request_type: 'CALL',
                // "re_captcha_token": token,
                at_url:
                  (provider.value
                    ? 'https://' + provider.value.domain
                    : 'https://dominternet.ru') + $route.fullPath,
                yandex_id: yaid() || '',
                google_id: gacid() || '',
                site_type: provider.value ? undefined : diProvider.value ? 'provider' : 'aggregator',
                site_city_fias_id: currentCity.value.fias_id,
                site_street_fias_id: street.value?.fias_id,
                site_house_id: houseId.value || 0,
                site_house_dadata: house.value?.dadata || {},
                ...meta,
              },
              extra,
              partner_id: partnerId.value ? +partnerId.value : undefined,
            },
          },
          {
            headers: {
              common: getCommonHeaders.value,
            },
          },
        )
      ).data
    }
    catch (e) {
      console.log(e)
      ctx.$sentryCaptureMessage(e)
    }
    return res
  }

  const uI = async (
    id: any,
    complete: boolean,
    lead?: any,
    tariff?: any,
    meta?: any,
    extra?: any,
    client?: any,
    address?: any,
  ) => {
    let res
    try {
      res = await interactionsApi.updateInteraction(
        {
          interactionId: id,
          interactionUpdate: {
            lead,
            complete,
            address: {
              auto_url: houseUrl.value,
              full_address: getAddressText.value?.fullAddress,
              city_fias_id: currentCity.value.fias_id,
              street_fias_id: street.value?.fias_id,
              house_id: houseId.value || undefined,
              house_dadata: house.value?.dadata || {},
              ...address,
            },
            client,
            tariff: {
              comparison_tariff_ids: compareListTariffs.value?.map(el => el.replace(/\D/g, '')),
              opened_tariff_ids: tariffsViewed.value?.map(el => el.replace(/\D/g, '')),
              ...tariff,
              provider_id: tariff?.provider_id || (tariff?.provider_id === null || provider.value ? null : passThroughProviderId.value) || undefined,
            },
            meta: {
              utm: utmArr.value?.length
                ? Object.fromEntries(utmArr.value)
                : undefined,
              request_type: 'CALL',
              at_url:
                (provider.value
                  ? 'https://' + provider.value.domain
                  : 'https://dominternet.ru') + $route.fullPath,
              yandex_id: yaid() || '',
              google_id: gacid() || '',
              site_type: provider.value ? undefined : diProvider.value ? 'provider' : 'aggregator',
              site_city_fias_id: currentCity.value.fias_id,
              site_street_fias_id: street.value?.fias_id,
              site_house_id: houseId.value || 0,
              site_house_dadata: house.value?.dadata || {},
              ...meta,
            },
            extra,
            partner_id: partnerId.value ? +partnerId.value : undefined,
          },
        },
        {
          headers: {
            common: getCommonHeaders.value,
          },
        },
      )
    }
    catch (e) {
      console.log(e)
      ctx.$sentryCaptureMessage(e)
    }
    return res
  }

  return { cI, uI }
}
